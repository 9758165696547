import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { SimpleMaskModule } from 'ngx-ion-simple-mask'

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './api/http.interceptor';

import { ComponentsModule } from './components/components.module';

import { LoginPageModule } from './login/login.module';
import { RegisterPageModule } from './register/register.module';
import { ForgotPageModule } from './forgot/forgot.module';
import { NewOrderPageModule } from './new-order/new-order.module';
import { SettingsPageModule } from './settings/settings.module';
import { ProfilePageModule } from './profile/profile.module';
import { OrderDetailsPageModule } from './order-details/order-details.module';


import { AuthGuardService } from './api/auth.guard';
import { MaskPipe } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { AppInjector } from './base/app.injector';
import { Injector } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu);


@NgModule({
  declarations: [
  	AppComponent  	
  ],
  entryComponents: [

  ],
  imports: [ 
    HttpClientModule, 
    BrowserModule, 
    MomentModule,
    FormsModule, 
    ReactiveFormsModule, 
    IonicModule.forRoot(), 
    NgxMaskModule.forRoot(),
    AppRoutingModule, 
    ComponentsModule,
    LoginPageModule,
    RegisterPageModule,
    ForgotPageModule,
    ProfilePageModule,    
    OrderDetailsPageModule
    ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },  
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },  
    AuthGuardService,    
    MaskPipe,
  	{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
    constructor(injector: Injector) {
        AppInjector.injector = injector;
    }  
}
