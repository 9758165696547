import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { BMRegisterUser, BMRegisterUserType, UsersClient } from '../api/clients';
import { BaseComponent } from '../base/base.component';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage extends BaseComponent implements OnInit {

  fields: FormGroup;

  constructor(
    private maskPipe: MaskPipe,
    private fb: FormBuilder,
    private api: UsersClient,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {super();}

  ngOnInit() {
    this.fields = this.fb.group({
      name: ['', Validators.required],      
      phone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm: ['', [Validators.required, Validators.minLength(6)]]      
    });
    console.log(this.fields);
  }

  async register(){

    const loading = await this.loadingController.create();
    await loading.present();

    var user = new BMRegisterUser();

    user.phone = this.fields.value.phone;
    user.password = this.fields.value.password;
    user.name = this.fields.value.name;
    user.type = BMRegisterUserType.Client;

    
    this.api.create(user).subscribe(
      async (res) => {
        await loading.dismiss();        
        this.showMessage("Отлично!", "Вы успешно зарегистрированы!");
        this.router.navigateByUrl('/login', { replaceUrl: true });
      },
      async (res) => {
        await loading.dismiss();
        console.log(res);
      }
    );
  }

  // Easy access for form fields
  get name() {
    return this.fields.get('name');
  }
  
  get phone() {
    return this.fields.get('phone');
  }  

  get password() {
    return this.fields.get('password');
  }

  get confirm() {
    return this.fields.get('confirm');
  }

  updateWithMask(event) {  
    this.fields.controls.phone.setValue(this.maskPipe.transform(event.currentTarget.value, '+0 (000) 000-00-00'));
  }  

}
