import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePage } from './profile.page';
import { SettingsPage } from '../settings/settings.page';
import { MainSettingsPage } from '../settings/main.settings.page';
import { NotificationsSettingsPage } from '../settings/notifications.page';
import { ContactsSettingsPage } from '../settings/contacts.page';
import { PaymentsPage } from '../payments/payments.page';
import { FeedbacksPage } from '../feedbacks/feedbacks.page';

const routes: Routes = [
  {
    path: 'view',
    component: ProfilePage, 
  }, 
  {
    path: 'settings',
    loadChildren: () => import('../settings/settings.module').then(m => m.SettingsPageModule)
    /*children: [
      {
        path: 'mainsettings',
        component: MainSettingsPage
      },
      {
        path: 'notificationssettings',
        component: NotificationsSettingsPage
      },
      {
        path: 'contactssettings',
        component: ContactsSettingsPage
      },
      {
        path: '',
        redirectTo: './mainsettings',
        pathMatch: 'full'
      }     
    ] */      
  },
  {
    path: '',
    redirectTo: 'view',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfilePageRoutingModule {}
