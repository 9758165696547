import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersClient, BMOrder, TeamsClient, BMTeam, BMEventDTO } from '../api/clients'
import { EnumsService } from '../api/enums.service';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../base/base.component';
import { OffersPageComponent } from './offers.page';
import { ODataClient } from '../api/odata.client';
import { SignalRService } from '../api/signalr.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.page.html',
  styleUrls: ['./order-details.page.scss'],
})
export class OrderDetailsPage extends BaseComponent implements OnInit {

	id : string;
	order : BMOrder;
  offers: BigInteger;


  constructor(
    private signal: SignalRService,
  	private enums : EnumsService, 
  	private api: OrdersClient, 
    private teamapi: TeamsClient,
    private odata: ODataClient<BMEventDTO>,
    private alertController: AlertController,
    private modalController: ModalController,
  	private route: ActivatedRoute
  	) { 
      super();
    }


  ngOnInit() {
    this.signal.newEvent.subscribe(e=>{
      console.log(e);
      if (e.OrderId == this.order.id)
        this.refreshOrder();
    });
  	return this.route.params.subscribe(params=>{
  		return this.api.get(params.id).subscribe(order=>{
  			this.order = order;
        if (order.teamId)
          this.teamapi.get(order.teamId).subscribe((team)=>{
            this.order.team = team;
          })        
        this.loadOffers();
  		})
  	})
  }

  loadOffers() {
    this.odata.get('/odata/odtEvents?$count=true&Classes=Offer&Types=Created&orderId=' + this.order.id).subscribe(data=>{
      this.offers = data['@odata.count'];
    })
  }

  canCancel(){
    var states = [ "OrderCreated" ]
    return states.indexOf( this.order.state ) >= 0;
  }
  canEdit() {
    var states = [ "OrderCreated" ]
    return states.indexOf( this.order.state ) >= 0;    
  }

  async cancelOrder() {
       const alert = await this.alertController.create({
        header: 'Отмена заказа',
        message: 'Желаете отменить этот заказ?',
      buttons: [
        {
          text: 'Да',
          role: 'yes',
          cssClass: 'secondary',
          handler: (blah) => {
            this.api.clientcancel(this.order.id, null).subscribe( order =>{
               this.showMessage("", "Заказ успешно отменен!");
               this.router.navigate(['../']);
            })
          }
        }, {
          text: 'Нет',
          role: 'no',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
      });

      await alert.present();
  }

  async showOffers() {
      const modal = await this.modalController.create({
      component: OffersPageComponent,
       componentProps: {
        'order': this.order
      }
    });
    return await modal.present();
  }

  refreshOrder(){
    console.log('refresh order')
    this.api.get(this.order.id).subscribe((order)=>{
      this.order = order;
      if (order.teamId)
        this.teamapi.get(order.teamId).subscribe((team)=>{
          this.order.team = team;
        })
    });
    this.loadOffers();
  }  
}
